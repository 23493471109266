import { Request } from 'express';
import get from 'lodash/get';
import {
  MangafeedReadPayload,
  MangafeedSerialReadPayload,
} from '../../../fetchr/services/Home/Dokusho/DokushoConfig';
import {
  readMangafeedData,
  readMangafeedSerialData,
} from '../../../fetchr/services/Home/Dokusho/DokushoShared';
import {
  ASYNC_STATUS,
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';

type State = Partial<MangafeedReadPayload>;

type Payload = Partial<State>;

const initialState: State = {
  newly: null,
  recommend: null,
  serial: null,
  isMangafeedLoading: false,
};

/**
 * マンガフィード 初期化
 */
export const REFRESH_MANGAFEED = 'Home/HomeMangafeed/REFRESH_MANGAFEED';
export const refreshMangafeed = createAction<Payload>(REFRESH_MANGAFEED, () => {
  return {
    newly: null,
    recommend: null,
    serial: null,
    isMangafeedLoading: true,
  };
});

/**
 * マンガフィード
 */
export const FETCH_MANGAFEED = 'Home/HomeMangafeed/FETCH_MANGAFEED';
export const fetchMangafeed = createAction<Payload>(
  FETCH_MANGAFEED,
  async (req?) => {
    try {
      const { data: mangafeed } = await readMangafeedData(req);
      return {
        ...mangafeed,
        isMangafeedLoading: false,
      };
    } catch {
      return {};
    }
  },
);

export const FETCH_MANGAFEED_SERIAL_START =
  'Home/HomeMangafeed/FETCH_MANGAFEED_SERIAL_START';
export const fetchMangafeedSerialStart = createAction<Payload>(
  FETCH_MANGAFEED_SERIAL_START,
  () => {
    return {};
  },
);

export const FETCH_MANGAFEED_SERIAL_COMPLETE =
  'Home/HomeMangafeed/FETCH_MANGAFEED_SERIAL_COMPLETE';
export const fetchMangafeedSerialComplete = createAction<Payload>(
  FETCH_MANGAFEED_SERIAL_COMPLETE,
  (mangafeed: MangafeedReadPayload, serial?: MangafeedSerialReadPayload) => {
    if (!serial) {
      mangafeed.serial.status = ASYNC_STATUS.failure;
    } else {
      // replace serial so it is detected as new state
      const _serial = { ...serial };
      _serial.entityList.unshift(...mangafeed.serial.entityList);
      mangafeed.serial = _serial;
    }
    return {
      ...mangafeed,
    };
  },
);

/**
 * マンガフィード リロード
 */
export const reloadMangafeed: ThunkActionCreator = (req?: Request) => {
  return async (dispatch) => {
    // 取得済みのデータを削除し、ローディングのstatusに
    dispatch(refreshMangafeed());

    // FETCHし直す
    dispatch(fetchMangafeed(req));
  };
};

/**
 * マンガフィード もっと見る処理
 */
export const fetchMangafeedSerial: ThunkActionCreator = (req?: Request) => {
  return async (dispatch, getState) => {
    const { homeMangafeed } = getState();
    const status = get(homeMangafeed, 'serial.status', null);
    const offset = get(homeMangafeed, 'serial.offset', null);

    if (status === ASYNC_STATUS.success && homeMangafeed && offset) {
      dispatch(fetchMangafeedSerialStart());
      const { data: serial } = await readMangafeedSerialData(req, offset);
      dispatch(fetchMangafeedSerialComplete(homeMangafeed, serial));
    }
  };
};

export const homeMangafeed = handleActions<State, Payload>(
  {
    [REFRESH_MANGAFEED](state, { payload }) {
      return {
        ...payload,
      };
    },
    [FETCH_MANGAFEED](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_MANGAFEED_SERIAL_START](state) {
      const mangafeed = { ...state };
      mangafeed.serial.status = ASYNC_STATUS.loading;
      return {
        ...mangafeed,
      };
    },
    [FETCH_MANGAFEED_SERIAL_COMPLETE](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);
