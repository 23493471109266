import { composeRoute } from '../../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';
import { fetchApps } from '../../../../contexts/Apps/Top';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('アプリ', 'apps'));
  },
  async clientLoader({ dispatch }, { params }) {
    await dispatch(fetchApps(params));
  },
})(getAsyncComponent(() => import('./AppsTop')));
