/**
 * デザイン仕様にもあるレベルの文言まとめ
 * 該当文言は、必ずここから使うようにしてください
 */
export const MORE_TEXT = 'もっと見る';

export const INPUT_TITLE = 'キーワードを入力';

export const TOPICS_TAG = {
  hpop: '大人気',
  hot: 'HOT!',
};
