import React from 'react';
import cx from 'classnames';
export { AdcrossType } from '../../../webApi/Adcross';
import {
  TrackableViewport,
  TrackableViewportProps,
} from '../TrackableViewport';

const ViewSection: React.FC<TrackableViewportProps> = ({
  className,
  ...props
}) => <TrackableViewport className={cx('ViewSection', className)} {...props} />;

ViewSection.defaultProps = {
  rootMargin: '-16px',
  element: 'section',
};

export default ViewSection;
