import React from 'react';
import cx from 'classnames';

interface Props {
  element?: string;
  className?: string;
  children?: React.ReactNode;
}

const Ellipsis: React.FC<Props> = ({ element, className, ...props }) => {
  return React.createElement(element, {
    className: cx('Ellipsis', className),
    ...props,
  });
};

Ellipsis.defaultProps = {
  element: 'p',
};

export default Ellipsis;
