import { Request } from 'express';
import { BlogLatestEntrySchema } from '../../../../services/homeApi/response/HomeService';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import { latestEntryConfig } from './LatestEntryConfig';

export function getLatestEntry(req?: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<{ blogLatestEntry: BlogLatestEntrySchema[] }>(
    latestEntryConfig.name,
  );
}
