import { fetchHistory } from '../../../contexts/Home/History';
import {
  fetchLatestEntry,
  fetchAppData,
  fetchNotificationCenterData,
  fetchRecommendNewGame,
  fetchSpHomeData,
  fetchFooterCarouselBannerData,
  fetchBlogRankingAndFollowFeedAndRecommendBlogger,
  fetchHomeMaintenance,
  fetchHomeInitialData,
} from '../../../contexts/Home/HomeTop';
import { fetchMangafeed } from '../../../contexts/Home/MangaFeed';
import { asyncErrorHandler } from '../../../lib/AsyncTaskUtil';
import { getAsyncComponent } from '../../../lib/ComponentUtils';
import { createLoginUrl } from '../../../lib/UrlUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';

export default composeRoute({
  async routerLoader({ req, dispatch }) {
    await Promise.all(
      [
        {
          functionName: 'setTitle',
          taskFunction: dispatch(setTitle(null, 'home')),
        },
        {
          functionName: 'fetchHomeMaintenance',
          taskFunction: dispatch(fetchHomeMaintenance(req)),
        },
        {
          functionName: 'fetchHomeInitialData',
          taskFunction: dispatch(fetchHomeInitialData(req)),
        },
        {
          functionName: 'fetchRecommendNewGame',
          taskFunction: dispatch(fetchRecommendNewGame(req)),
        },
        {
          functionName: 'fetchAppData',
          taskFunction: dispatch(fetchAppData(req)),
        },
      ].map((p) => {
        const { functionName, taskFunction } = p;
        return asyncErrorHandler(
          taskFunction,
          `[pages/Home/index.tsx - ${functionName}]`,
        );
      }),
    );
  },
  async clientLoader({ dispatch }) {
    await Promise.all(
      [
        {
          functionName: 'fetchNotificationCenterData',
          taskFunction: dispatch(fetchNotificationCenterData()),
        },
        {
          functionName: 'fetchSpHomeData',
          taskFunction: dispatch(fetchSpHomeData()),
        },
        {
          functionName: 'fetchBlogRankingAndFollowFeedAndRecommendBlogger',
          taskFunction: dispatch(
            fetchBlogRankingAndFollowFeedAndRecommendBlogger(),
          ),
        },
        {
          functionName: 'fetchHistory',
          taskFunction: dispatch(fetchHistory()),
        },
        {
          functionName: 'fetchLatestEntry',
          taskFunction: dispatch(fetchLatestEntry()),
        },
        {
          functionName: 'fetchFooterCarouselBannerData',
          taskFunction: dispatch(fetchFooterCarouselBannerData()),
        },
        {
          functionName: 'fetchMangafeed',
          taskFunction: dispatch(fetchMangafeed()),
        },
      ].map((p) => {
        const { functionName, taskFunction } = p;
        return asyncErrorHandler(
          taskFunction,
          `[pages/Home/index.tsx - ${functionName}]`,
        );
      }),
    );
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    createLoginUrl(),
  )(getAsyncComponent(() => import('./Home'))),
);
