import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  homeTopInitialConfig,
  SpHomeInitialDataReadPayload,
} from './HomeTopInitialConfig';

/**
 * SPホームTOP
 */
export function readSpHomeInitialData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<SpHomeInitialDataReadPayload>(homeTopInitialConfig.name);
}
