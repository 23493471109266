import logger from './Logger';

/*
 * 非同期処理のエラーを握りつぶし，デフォルトの値を返す
 */
export async function asyncErrorHandler<T>(
  task: Promise<T>,
  errorText = '[Error Text Not Set.]', // [file name - function name - etc]
  defaultValue: T = null,
): Promise<T> {
  try {
    return await task;
  } catch (err) {
    logger.warn(err, `Async task failed for ${errorText}`);
    return defaultValue;
  }
}
