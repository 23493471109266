import FetchrSharedFactory from '../../FetchrSharedFactory';
import { recommendNewGameConfig } from './RecommendNewGameConfig';

/**
 * SPホームTOP
 */
export function readRecommendNewGameData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<any>(recommendNewGameConfig.name);
}
