import React from 'react';
import get from 'lodash/get';
import { connect, DispatchProp } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { fetchNotificationCount } from '../../../contexts/Notification/Notification';
import { composeContainer, composeRoute } from '../../../lib/ReactRouterUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';
import SpHeader from '../../organizers/AppShell/SpHeader';
import SpFooter from '../../organizers/AppShell/SpFooter';
import SpSideMenu from '../../organizers/AppShell/SpSideMenu';

export interface Props {
  isAuthenticated: boolean;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: get(state, 'loginInfo.isAuthenticated'),
  };
};

class AppShell extends React.Component<
  Props & RouteConfigComponentProps<any> & DispatchProp<any>
> {
  render() {
    const { route } = this.props;
    return (
      <div className="AppShell">
        <Helmet
          defaultTitle={process.env.TITLE}
          titleTemplate="%s | Ameba（アメーバ）"
        />
        {/* サイドメニュー */}
        <div className="AppShell_SideMenu">
          <SpSideMenu />
        </div>
        {/* メインコンテンツ */}
        <div className="AppShell_MainContainer">
          <SpHeader />
          <main>{renderRoutes(route.routes)}</main>
          <SpFooter />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  composeRoute({
    async routerLoader({ dispatch }) {
      dispatch(setTitle(null));
    },
    async clientLoader(props) {
      if (props.isAuthenticated) {
        props.dispatch(fetchNotificationCount());
      }
    },
  })(composeContainer()(AppShell)),
);
