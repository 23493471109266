import { AbemaProgramSchema } from '../../../../services/abema/response/AbemaService';
import { MypageApiMaintenanceText } from '../../../../types/apis/mypage-api';
import { create } from '../../FetchrConfigFactory';

export const homeTopConfig = create('home/home_top');

export interface SpHomeDataReadPayload {
  maintenanceText: MypageApiMaintenanceText[];
  abemaPickups: AbemaProgramSchema[];
  searchValue: string;
}
