import { Request } from 'express';
import {
  AmebaPlatformApiRegistReaderSchema,
  HomeFollowFeedSchema,
} from '../../../../services/_sp/response/HomeService';
import { HomeRecommendBlogEntity } from '../../../../services/_sp/entity/HomeRecommendBlogEntity';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  followFeedConfig,
  recommendTalentBlogConfig,
} from './FollowFeedConfig';

/**
 * follow feed
 * @param {e.Request} req
 * @param {string} cursor
 * @return {Promise<HomeFollowFeedSchema>}
 */
export function getFollowFeed(req?: Request, cursor?: string) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomeFollowFeedSchema>(followFeedConfig.name, {
    cursor,
  });
}

/**
 * SP Home フォロー推奨タレントブロガー
 */
export function readRecommendTalentBlog(req, feedData) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomeRecommendBlogEntity[]>(
    recommendTalentBlogConfig.name,
    { feedData },
  );
}

/**
 * SP Home フォロー推奨タレントブロガーのフォロー処理
 * @param req
 * @param amebaId
 * @return {Promise<AmebaPlatformApiRegistReaderSchema>}
 */
export function updateRecommendTalentBlog(req?, amebaId?) {
  const fetchr = new FetchrSharedFactory({ req });
  const params = {
    amebaId,
  };
  return fetchr.update<AmebaPlatformApiRegistReaderSchema>(
    recommendTalentBlogConfig.name,
    params,
  );
}
