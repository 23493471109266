import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  title?: string;
  id?: string;
  role?: string;
  'aria-labelledby'?: string;
  'aria-hidden'?: boolean;
  children?: React.ReactNode;
}

export const HorizontalListItem: React.FC<Props> = ({
  className,
  ...props
}) => <li className={cx('HorizontalList_Item', className)} {...props} />;

const HorizontalList: React.FC<Props> = ({ children, className, ...props }) => (
  <ul className={cx('HorizontalList', className)} {...props}>
    {children}
  </ul>
);

export default HorizontalList;
