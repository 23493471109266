import { ROUTE_NAMES } from '../../../../../constants/routes';
import { IconTypes } from '../../../../atoms/Icon';

/**
 * 右のメニューに使われる要素
 */
export interface NavigationLinkProps {
  link: string;
  icon: string;
  key: string;
}

export const NAVIGATION_LINKS = {
  home: { link: ROUTE_NAMES.home, icon: IconTypes.Home, key: 'home' },
  profile: {
    link: `${process.env.DOMAIN_PROFILE_AMEBA_URL}/me`,
    icon: IconTypes.User,
    key: 'profile',
  },
  notifications: {
    link: ROUTE_NAMES.notifications,
  },
};
