import FetchrSharedFactory from '../../FetchrSharedFactory';
import { homeTopConfig, SpHomeDataReadPayload } from './HomeTopConfig';

/**
 * SPホームTOP
 */
export function readSpHomeData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<SpHomeDataReadPayload>(homeTopConfig.name);
}
