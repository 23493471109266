import React from 'react';
import cx from 'classnames';
import { SpinnerCircle } from '../Spinner';

interface Props {
  className?: string;
  isActive?: boolean;
  children?: any;
}

/**
 * @deprecated
 * 直接Spinnerを使うようにしてください
 */
const Loading: React.FC<Props> = ({ className, isActive, children }) => {
  if (!isActive) {
    return null;
  }
  return (
    <div className={cx('Loading', className)}>
      <SpinnerCircle />
      {children}
    </div>
  );
};

Loading.defaultProps = {
  isActive: true,
};

export default Loading;
