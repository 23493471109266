import { Request } from 'express';
import HomePopularTalentBlogEntity from '../../../../services/_sp/entity/HomePopularTalentBlogEntity';
import { RankingBlog } from '../../../../services/stat100/response/BlogRankingService';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import { popularTalentBlogConfig } from './PopularTalentBlogConfig';

export function readPopularTalentBlog(req?: Request, blogList?: RankingBlog[]) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomePopularTalentBlogEntity[]>(
    popularTalentBlogConfig.name,
    {
      blogList,
    },
  );
}
