import FetchrSharedFactory from '../../FetchrSharedFactory';
import { footerCarouselBannerConfig } from './FooterCarouselBannerConfig';

/**
 * SP HOME FOOTER CAROUSEL BANNER
 */
export function readSpHomeFooterCarouselBannerData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<any>(footerCarouselBannerConfig.name);
}
