import { IconTypes } from '../components/atoms/Icon';
import { getDokushoLoginRedirectUrl } from '../lib/UrlUtils';
import { ROUTE_NAMES } from './routes';

export interface SpMenuProps {
  title: string;
  icon?: string;
  linkUrl: string;
  key: string;
  isAmebame?: boolean;
  isExternal?: boolean; // 外部のページか否か
}

const KEY_PREFIX = 'menu-';
export const INFORMATION_KEY = `${KEY_PREFIX}-information`;

/**
 * SPのメニューで使うリンク集。
 */
export const SP_MENU = {
  // プラットフォーム内のページ
  home: {
    title: 'ホーム',
    icon: IconTypes.Home,
    linkUrl: ROUTE_NAMES.home,
    key: KEY_PREFIX + 'home',
    isExternal: false,
  },
  information: {
    title: 'お知らせ',
    icon: IconTypes.Notification,
    linkUrl: ROUTE_NAMES.notifications,
    isAmebame: true,
    key: INFORMATION_KEY,
    isExternal: false,
  },
  myapps: {
    title: 'マイアプリ',
    icon: IconTypes.Service,
    linkUrl: ROUTE_NAMES.myapps,
    isAmebame: true,
    key: KEY_PREFIX + 'myapps',
    isExternal: false,
  },
  game: {
    title: 'ゲーム',
    icon: IconTypes.Game,
    linkUrl: ROUTE_NAMES.game,
    isAmebame: true,
    key: KEY_PREFIX + 'game',
    isExternal: false,
  },
  coin: {
    title: 'コイン',
    icon: IconTypes.Coin,
    isAmebame: true,
    linkUrl: ROUTE_NAMES.coinSummary,
    key: KEY_PREFIX + 'coin',
    isExternal: false,
  },
  setting: {
    title: '設定',
    linkUrl: ROUTE_NAMES.settings,
    key: KEY_PREFIX + 'setting',
    isExternal: false,
  },
  contact: {
    title: 'お問い合わせ',
    linkUrl: process.env.DOMAIN_HELPS_URL + '/inquiry.html',
    key: KEY_PREFIX + 'contact',
    isExternal: true,
  },

  // ブログページ
  blog: {
    title: 'ブログ管理',
    icon: IconTypes.Blog,
    linkUrl: process.env.DOMAIN_BLOG_AMEBA_URL + '/ucs/sptop.do',
    key: KEY_PREFIX + 'blog',
    isExternal: true,
  },
  official: {
    title: '芸能人・有名人',
    icon: IconTypes.Popular,
    linkUrl: process.env.DOMAIN_OFFICIAL_URL,
    key: KEY_PREFIX + 'official',
    isExternal: true,
  },

  // その他サービス
  satori: {
    title: '占い',
    icon: IconTypes.Night,
    linkUrl: process.env.DOMAIN_SATORI_URL,
    key: KEY_PREFIX + 'satori',
    isExternal: true,
  },
  dokusho: {
    title: 'マンガ',
    icon: IconTypes.Dokusho,
    linkUrl: getDokushoLoginRedirectUrl(process.env.DOMAIN_DOKUSHO_URL),
    key: KEY_PREFIX + 'dokusho',
    isExternal: true,
  },
  pigg: {
    title: 'ピグ',
    icon: IconTypes.Pigg,
    linkUrl: process.env.DOMAIN_PIGG_SP_URL,
    key: KEY_PREFIX + 'pigg',
    isExternal: true,
  },
  dotmoney: {
    title: 'ドットマネー',
    icon: IconTypes.Dotmoney,
    linkUrl: process.env.DOMAIN_DOTMONEY_URL + '/mall/?_g=0',
    key: KEY_PREFIX + 'dotmoney',
    isExternal: true,
  },
  message: {
    title: 'メッセージ',
    icon: IconTypes.Mail,
    linkUrl: 'http://msg.ameba.jp/receiveBox.do',
    key: KEY_PREFIX + 'message',
    isExternal: true,
  },
  rules: {
    title: '利用規約',
    linkUrl: process.env.DOMAIN_HELP_URL + '/ameba-rules/',
    key: KEY_PREFIX + 'rules',
    isExternal: true,
  },
  amebanews: {
    title: 'ニュース',
    icon: IconTypes.News,
    linkUrl: process.env.DOMAIN_AMEBA_NEWS_URL,
    key: KEY_PREFIX + 'amebanews',
    isExternal: true,
  },
  specifiedCommercialTransactions: {
    title: '特定商取引法に基づく表記',
    linkUrl: process.env.DOMAIN_HELPS_URL + '/rules/post_855.html',
    key: KEY_PREFIX + 'specified-commercial-transactions',
    isExternal: true,
  },
};
