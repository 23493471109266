import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import get from 'lodash/get';
import cx from 'classnames';
import { fetchModalMenu } from '../../../../contexts/AppShell/ModalMenu';
import { ReadPayload } from '../../../../fetchr/services/AppShell/ModalMenu/ModalMenuConfig';
import MineServiceIds from '../../../../constants/serviceIds';
import { UserProfileObject } from '../../../../services/userProfile/entity/UserProfile';
import Tap from '../../../atoms/Tap';
import Ellipsis from '../../../atoms/Ellipsis';
import Icon, { IconTypes } from '../../../atoms/Icon';
import { SpHeaderType } from '../../../../contexts/AppShell/SpHeader';
import Button, { ButtonSize, ButtonType } from '../../../atoms/Button';
import { LOGIN, REGISTER_LOGIN } from '../../../../constants/amebaLogin';
import { getAccountsRegistrationLinkUrl } from '../../../../lib/UrlUtils';
import { ModalMenu } from './ModalMenu';
import { NAVIGATION_LINKS, NavigationLinkProps } from './constants/headerTypes';

interface Props {
  isAmbApp: boolean;
  isCaApp: boolean;
  headerTitle: string;
  headerType: SpHeaderType;
  pathname: string;
  user: UserProfileObject;
  isAuthenticated: boolean;
  modalMenu: ReadPayload;
  unreadCount: number;
}

interface State {
  showModalMenu: boolean;
  pathname: string;
}

const SECTION_ID = 'sp-header';

/**
 * グローバルヘッダー
 */
class SpHeader extends React.Component<Props & DispatchProp<any>, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    if (nextProps.pathname !== prevState.pathname) {
      return {
        showModalMenu: false,
        pathname: nextProps.pathname,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      showModalMenu: false,
      pathname: props.pathname,
    };
    this.switchModalMenu = this.switchModalMenu.bind(this);
    this.switchPageScrollLock = this.switchPageScrollLock.bind(this);
  }

  componentDidUpdate() {
    this.switchPageScrollLock();
  }

  switchPageScrollLock() {
    const { showModalMenu } = this.state;
    document.getElementsByTagName('body')[0].style.overflow = showModalMenu
      ? 'hidden'
      : '';
  }

  /**
   * モーダルメニューの開閉
   */
  switchModalMenu() {
    const { showModalMenu } = this.state;

    if (
      !showModalMenu &&
      (!this.props.modalMenu.purchase || !this.props.modalMenu.dotMoney)
    ) {
      // メニュー内の必要データを取得
      this.props.dispatch(fetchModalMenu());
    }
    this.setState({
      showModalMenu: !showModalMenu,
    });
  }

  /**
   * ヘッダー用ボタン
   */
  renderButton(props: {
    icon: string;
    contentId: string;
    to?: string;
    href?: string;
    active?: boolean;
    onClick?: () => void;
    notifications?: number;
  }) {
    const { notifications } = props;
    // カウントが100以上か否か
    const overCount = notifications > 99;
    return (
      <Tap
        to={props.to}
        href={props.href}
        track={{
          section_id: SECTION_ID,
          tap_id: 'header-button',
          content_ids: [props.contentId],
          service_id: MineServiceIds.Ameba,
        }}
        onClick={props.onClick}
        className={cx('MenuHeader_Button', '-' + props.contentId, {
          '-active': props.active,
        })}
      >
        <Icon
          iconClass={props.icon}
          className={cx('MenuHeader_Button_Icon', {
            '-hasNotification': !!notifications,
          })}
        />
        {notifications > 0 && (
          <span className="MenuHeader_Button_Notification">
            {overCount ? '99+' : notifications}
          </span>
        )}
      </Tap>
    );
  }

  renderNavigationLink(navigationLink: NavigationLinkProps) {
    const { unreadCount: count, isAuthenticated } = this.props;
    return (
      <div className="MenuHeader_NavigationLink">
        {
          /* Home/Mypageボタン */
          this.renderButton({
            icon: navigationLink.icon,
            contentId: navigationLink.key,
            to:
              navigationLink === NAVIGATION_LINKS.home
                ? navigationLink.link
                : '',
            href:
              navigationLink === NAVIGATION_LINKS.profile
                ? navigationLink.link
                : '',
          })
        }
        {
          /* お知らせボタン */
          isAuthenticated &&
            this.renderButton({
              icon: IconTypes.Notification,
              contentId: 'notifications',
              href: NAVIGATION_LINKS.notifications.link,
              notifications: count,
              active: count > 0,
            })
        }
      </div>
    );
  }

  renderRegisterLink() {
    return (
      <div className="MenuHeader_RegisterLink">
        <Button
          className="MenuHeader_RegisterLink_Button"
          href={getAccountsRegistrationLinkUrl(REGISTER_LOGIN.linkUrl, {
            utm_medium: 'ameba',
            utm_source: new URL(process.env.DOMAIN_WWW_URL).host,
            utm_campaign: 'ameba_sp-home-header_register_link',
          })}
          buttonType={ButtonType.Primary}
          size={ButtonSize.Free}
          track={{
            section_id: SECTION_ID,
            tap_id: 'register-link',
            service_id: MineServiceIds.Ameba,
          }}
        >
          新規登録
        </Button>
        <Button
          className="MenuHeader_RegisterLink_Button"
          href={LOGIN.linkUrl}
          buttonType={ButtonType.Secondary}
          size={ButtonSize.Free}
          track={{
            section_id: SECTION_ID,
            tap_id: 'login-link',
            service_id: MineServiceIds.Ameba,
          }}
        >
          ログイン
        </Button>
      </div>
    );
  }

  render() {
    const { showModalMenu } = this.state;
    const {
      isAmbApp,
      isCaApp,
      modalMenu,
      headerTitle,
      headerType,
      isAuthenticated,
    } = this.props;

    // NOTE: 対象のヘッダータイプかつアプリの場合はヘッダーを表示しない
    if (
      ['copyright', 'apps', 'game'].includes(headerType) &&
      (isAmbApp || isCaApp)
    ) {
      return null;
    }

    if (headerType === 'campaign_misc') {
      return null;
    }

    const showModalMenuButton =
      ['home', 'copyright', 'apps', 'game', 'other'].includes(headerType) &&
      isAuthenticated;
    // TODO: 他ページが作成されたあと、ログイン状態か確認でnavigation周りのロジック修正
    const navigationLink =
      !headerType || headerType === 'top'
        ? null
        : this.props.headerType === 'home'
          ? NAVIGATION_LINKS.profile
          : NAVIGATION_LINKS.home;
    const titleClsName = cx('MenuHeader_Title', {
      '-hasNavigationLink': !!navigationLink,
    });

    // topページではヘッダーに登録導線を追加
    const registerLink = headerType === 'top';

    return (
      <header className="SpHeader">
        <div className="MenuHeader">
          {showModalMenuButton &&
            this.renderButton({
              icon: IconTypes.Hanburger,
              contentId: 'menu',
              onClick: () => {
                this.switchModalMenu();
              },
              active: true,
            })}
          {headerTitle ? (
            <Ellipsis element="h1" className={titleClsName}>
              {headerTitle}
            </Ellipsis>
          ) : (
            <h1 className={titleClsName}>
              <img
                src="/images/common/logo.png"
                alt="Ameba(アメーバ)"
                height={25}
              />
            </h1>
          )}
          {navigationLink && this.renderNavigationLink(navigationLink)}
          {registerLink && this.renderRegisterLink()}
        </div>
        {showModalMenu && (
          <ModalMenu
            user={this.props.user}
            onClose={this.switchModalMenu}
            sectionId={SECTION_ID}
            dotMoney={modalMenu.dotMoney}
            purchase={modalMenu.purchase}
          />
        )}
      </header>
    );
  }
}

export default connect((state) => {
  return {
    isAmbApp: get(state, 'userAgent.isAmbApp'),
    isCaApp: get(state, 'userAgent.isCaApp'),
    headerTitle: get(state, 'spHeader.title'),
    headerType: get(state, 'spHeader.headerType'),
    pathname: get(state, 'router.location.pathname') || '',
    user: get(state, 'loginInfo.user'),
    isAuthenticated: get(state, 'loginInfo.isAuthenticated'),
    modalMenu: get(state, 'modalMenu'),
    unreadCount: get(state, 'notification.unreadCount'),
  };
})(SpHeader);
