import { RankingBlog } from '../../stat100/response/BlogRankingService';

export const MvbEbizo: RankingBlog = {
  ameba_id: 'ebizo-ichikawa',
  blog_title: '市川團十郎白猿オフィシャルB',
  rank: 0,
  rank_before: 0,
  status: '',
  nick_name: '市川團十郎白猿',
  categories: [],
};

export const MvbMao: RankingBlog = {
  ameba_id: 'maokobayashi0721',
  blog_title: '小林麻央オフィシャルブログ「KOKORO.」',
  rank: 0,
  rank_before: 0,
  status: '',
  nick_name: '小林麻央',
  categories: [],
};

export const MvbList = [MvbEbizo, MvbMao];
