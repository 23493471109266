import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  blogRankingConfig,
  HomeBlogRankingReadPayload,
} from './BlogRankingConfig';

export function readBlogRankingData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomeBlogRankingReadPayload>(blogRankingConfig.name);
}
