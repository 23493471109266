import AmebaMoneyInfo from '../../../../services/_common/entity/AmebaMoneyInfo';
import Purchase from '../../../../services/snsApi/entity/Purchase';
import { create } from '../../FetchrConfigFactory';

export const modalMenuConfig = create('app_shell/modalMenu');

export interface ReadPayload {
  purchase: Purchase;
  dotMoney: AmebaMoneyInfo;
}
