import { composeRoute } from '../../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../../lib/ComponentUtils';
import { setTitle } from '../../../../contexts/AppShell/SpHeader';
import { fetchGameTop } from '../../../../contexts/Game/Top';
import { fetchApps } from '../../../../contexts/Apps/Top';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('ゲーム', 'game'));
  },
  async clientLoader({ dispatch }) {
    await Promise.all([dispatch(fetchGameTop()), dispatch(fetchApps())]);
  },
})(getAsyncComponent(() => import('./GameTop')));
