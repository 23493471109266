import { Request } from 'express';
import {
  getFollowFeed,
  readRecommendTalentBlog,
  updateRecommendTalentBlog,
} from '../../../fetchr/services/Home/FollowFeed/FollowFeedShared';
import ServiceIds from '../../../constants/serviceIds';
import { trackViewSection } from '../../../fetchr/services/Common/Mine/MineShared';
import {
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { HomeFollowFeedPropsSchema } from '../../../services/_sp/response/HomeService';
import { BlogListItemSchema } from '../../../services/blog/response/BlogCommon';

type State = Partial<HomeFollowFeedPropsSchema>;

type Payload = Partial<State>;

const initialState: State = {
  nextCursor: null,
  followFeed: null,
  appDlButton: null,
  isFollowFeedLoading: false,
  isFollowFeedError: false,
  recommendTalentBlog: [],
  followResult: null,
  updateRecommendTalentBlogAmebaId: '',
};

/**
 * フォローフィード データ取得
 */
export const FETCH_FOLLOW_FEED = 'Home/HomeTop/FETCH_FOLLOW_FEED';
export const fetchFollowFeed = createAction<Payload>(
  FETCH_FOLLOW_FEED,
  async (req?: Request, cursor?: string) => {
    const { data } = await getFollowFeed(req, cursor);

    if (data.followFeed && data.followFeed.checklist) {
      const amebaIds: string[] = data.followFeed.checklist.map(
        (item) => item.amebaId,
      );

      // 非同期で投げる
      trackViewSection(req, req ? req.originalUrl : window.location.href, {
        section_id: 'sp-home-followfeed',
        tap_id: 'followfeed',
        service_id: ServiceIds.Ameba,
        content_ids: amebaIds,
      }).catch(() => {
        /**/
      });
    }

    return {
      followFeed: data.followFeed ? data.followFeed.checklist : [],
      nextCursor: data.followFeed ? data.followFeed.nextCursor : null,
      appDlButton: data.appDlButton,
    };
  },
);

/**
 * フォローフィード 初期化
 */
export const INIT_FOLLOW_FEED = 'Home/HomeFollowFeed/INIT_FOLLOW_FEED';
export const initFollowFeed = createAction<Payload>(INIT_FOLLOW_FEED, () => {
  return {};
});

/**
 * フォローフィード データローディング状態
 */
export const SWITCH_FOLLOW_FEED_LOADING =
  'Home/HomeFollowFeed/SWITCH_FOLLOW_FEED_LOADING';
export const switchFollowFeedLoading = createAction<Payload>(
  SWITCH_FOLLOW_FEED_LOADING,
  (isFollowFeedLoading: boolean) => {
    return {
      isFollowFeedLoading,
    };
  },
);

/**
 * フォローフィード 次のデータを取得
 */
export const fetchFollowFeedWithCursor: ThunkActionCreator = (
  req?: Request,
) => {
  return async (dispatch, getState) => {
    const { homeFollowFeed } = getState();
    // cursorから次のfeedをリクエスト
    await dispatch(fetchFollowFeed(req, homeFollowFeed.nextCursor));
  };
};

/**
 * フォローフィード リロード
 */
export const reloadFollowFeed: ThunkActionCreator = (req?: Request) => {
  return async (dispatch) => {
    // 状態の初期化
    dispatch(initFollowFeed());

    // loading表示
    dispatch(switchFollowFeedLoading(true));
    // FETCHし直す
    await dispatch(fetchFollowFeed(req));
  };
};

/**
 * おすすめブロガーの取得
 * @type {string}
 */
export const FETCH_RECOMMENDED_BLOGGER =
  'Home/HomeTop/FETCH_RECOMMENDED_BLOGGER';
export const fetchRecommendedBlogger = createAction<Payload>(
  FETCH_RECOMMENDED_BLOGGER,
  async (req: Request, feedData: BlogListItemSchema[]) => {
    // followfeedの1番目のデータを元に推薦するブロガーのデータを持ってくる
    const { data: recommendTalentBlog } = await readRecommendTalentBlog(
      req,
      feedData,
    );

    return {
      recommendTalentBlog,
    };
  },
);

/**
 * おすすめブロガーフォロー処理の一連の流れ
 * @param {e.Request} req
 * @param {string} amebaId
 * @return {(dispatch, getState) => Promise<void>}
 */
export const followBlogger: ThunkActionCreator = (
  req?: Request,
  amebaId?: string,
) => {
  return async (dispatch, getState) => {
    // フォロー処理
    await dispatch(followRecommendedBlogger(req, amebaId));

    const { homeFollowFeed } = getState();
    const { followResult } = homeFollowFeed;

    if (!!followResult && followResult.status === '0') {
      dispatch(updateRecommendedBlogger(amebaId));
    }
  };
};

/**
 * おすすめブロガーをフォロー
 * @type {string}
 */
export const FOLLOW_RECOMMENDED_BLOGGER =
  'Home/HomeFollowFeed/FOLLOW_RECOMMENDED_BLOGGER';
export const followRecommendedBlogger = createAction<Payload>(
  FOLLOW_RECOMMENDED_BLOGGER,
  async (req?: Request, amebaId?: string) => {
    const { data: status } = await updateRecommendTalentBlog(req, amebaId);

    return {
      followResult: status,
    };
  },
);

/**
 * フォロー成功時
 * @type {string}
 */
export const UPDATE_RECOMMENDED_BLOGGER =
  'Home/HomeFollowFeed/UPDATE_RECOMMENDED_BLOGGER';
export const updateRecommendedBlogger = createAction<Payload>(
  UPDATE_RECOMMENDED_BLOGGER,
  async (amebaId: string) => {
    return {
      updateRecommendTalentBlogAmebaId: amebaId,
    };
  },
);

/**
 * フォロー失敗時のポップアップ閉じる際の処理
 * @type {string}
 */
export const RESET_RECOMMENDED_BLOGGER_FOLLOW_RESULT =
  'Home/HomeFollowFeed/RESET_RECOMMENDED_BLOGGER_FOLLOW_RESULT';
export const resetRecommendedBloggerFollowResult = createAction<Payload>(
  RESET_RECOMMENDED_BLOGGER_FOLLOW_RESULT,
  async () => {
    return {};
  },
);

export const homeFollowFeed = handleActions<State, Payload>(
  {
    [INIT_FOLLOW_FEED](state) {
      return {
        ...state,
        followFeed: initialState.followFeed,
        nextCursor: initialState.nextCursor,
        appDlButton: initialState.appDlButton,
        isFollowFeedError: false,
      };
    },
    [SWITCH_FOLLOW_FEED_LOADING](state, { payload }) {
      return {
        ...state,
        isFollowFeedLoading: payload.isFollowFeedLoading,
      };
    },
    [FETCH_FOLLOW_FEED](state, { payload, error }) {
      if (error) {
        return {
          ...state,
          isFollowFeedLoading: false,
          isFollowFeedError: error,
        };
      }

      const followFeed = [];
      if (state.followFeed && state.followFeed.length) {
        followFeed.push(...state.followFeed);
      }

      if (payload.followFeed && payload.followFeed.length) {
        followFeed.push(...payload.followFeed);
      }

      return {
        ...state,
        followFeed,
        nextCursor: payload.nextCursor,
        appDlButton: payload.appDlButton,
        isFollowFeedLoading: false,
        isFollowFeedError: false,
      };
    },
    [FETCH_RECOMMENDED_BLOGGER](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [FOLLOW_RECOMMENDED_BLOGGER](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [UPDATE_RECOMMENDED_BLOGGER](state, { payload }) {
      const id = payload.updateRecommendTalentBlogAmebaId;
      const updateRecommendTalentBlog = state.recommendTalentBlog.map(
        (data) => {
          const result = Object.assign({}, data);
          if (id === result.amebaId) {
            result.isFollow = true;
          }
          return result;
        },
      );

      return {
        ...state,
        recommendTalentBlog: updateRecommendTalentBlog,
      };
    },
    [RESET_RECOMMENDED_BLOGGER_FOLLOW_RESULT](state) {
      return {
        ...state,
        followResult: null,
      };
    },
  },
  initialState,
);
