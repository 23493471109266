import { RouteConfig } from 'react-router-config';
import Ameba20thInvitationEventsDetail from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationEventsDetail';
import Ameba20thInvitationTicket from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationTicket';
import Ameba20thInvitationTop from '../../components/pages/Ameba20thInvitation/Ameba20thInvitationTop';
import AppDetail from '../../components/pages/App/AppDetail';
import Apps from '../../components/pages/Apps/AppsTop';
import AppShell from '../../components/pages/AppShell';
import CoinSummary from '../../components/pages/Coin/CoinSummary';
import CopyrightComplete from '../../components/pages/Copyright/CopyrightComplete';
import CopyrightConfirm from '../../components/pages/Copyright/CopyrightConfirm';
import CopyrightSearch from '../../components/pages/Copyright/CopyrightSearch';
import CopyrightTop from '../../components/pages/Copyright/CopyrightTop';
import CopyrightVideos from '../../components/pages/Copyright/CopyrightVideos';
import GameTop from '../../components/pages/Game/GameTop';
import Home from '../../components/pages/Home';
import Logout from '../../components/pages/Logout';
import MyAppsTop from '../../components/pages/MyApps/MyAppsTop';
import Nonamebaid from '../../components/pages/Nonamebaid';
import NotFound from '../../components/pages/NotFound';
import Notifications from '../../components/pages/Notifications';
import SettingsNotifications from '../../components/pages/Settings/SettingsNotifications';
import SettingsTop from '../../components/pages/Settings/SettingsTop';
import SettingsMangafeed from '../../components/pages/SettingsMangafeed';
import Top from '../../components/pages/Top';
import Tradelaw from '../../components/pages/Tradelaw';
import { PAGE_IDS, ROUTE_NAMES } from '../../constants/routes';

const mobileRoutes: RouteConfig[] = [
  {
    path: ROUTE_NAMES.logout,
    exact: true,
    component: Logout,
  },
  // TODO: 20th終わったら削除
  {
    path: ROUTE_NAMES.ameba20thInvitation,
    exact: true,
    component: Ameba20thInvitationTop,
    page_id: PAGE_IDS.ameba20thInvitation,
  },
  {
    path: ROUTE_NAMES.ameba20thInvitationEventsDetail,
    exact: true,
    component: Ameba20thInvitationEventsDetail,
    page_id: PAGE_IDS.ameba20thInvitationEventsDetail,
  },
  {
    path: ROUTE_NAMES.ameba20thInvitationTicket,
    exact: true,
    component: Ameba20thInvitationTicket,
    page_id: PAGE_IDS.ameba20thInvitationTicket,
  },
  {
    path: ROUTE_NAMES.top,
    component: AppShell,
    routes: [
      {
        path: ROUTE_NAMES.top,
        exact: true,
        component: Top,
        page_id: PAGE_IDS.top,
      },
      {
        path: ROUTE_NAMES.home,
        exact: true,
        component: Home,
        page_id: PAGE_IDS.home,
      },
      {
        path: ROUTE_NAMES.nonamebaid,
        exact: true,
        component: Nonamebaid,
        page_id: PAGE_IDS.nonamebaid,
      },
      {
        path: ROUTE_NAMES.settings_mangafeed,
        exact: true,
        component: SettingsMangafeed,
        page_id: PAGE_IDS.settings_mangafeed,
      },
      {
        path: ROUTE_NAMES.notifications,
        exact: true,
        component: Notifications,
        page_id: PAGE_IDS.notifications,
      },
      {
        path: ROUTE_NAMES.settings,
        exact: true,
        component: SettingsTop,
        page_id: PAGE_IDS.settings,
      },
      {
        path: ROUTE_NAMES.settings_notifications,
        exact: true,
        component: SettingsNotifications,
        page_id: PAGE_IDS.settings_notifications,
      },
      {
        path: ROUTE_NAMES.coinSummary,
        exact: true,
        component: CoinSummary,
        page_id: PAGE_IDS.coin_summary,
      },
      {
        path: ROUTE_NAMES.tradelaw,
        exact: true,
        component: Tradelaw,
        page_id: PAGE_IDS.tradelaw,
      },
      {
        path: ROUTE_NAMES.copyright,
        exact: true,
        component: CopyrightTop,
        page_id: PAGE_IDS.copyright,
      },
      {
        path: ROUTE_NAMES.copyrightVideos,
        exact: true,
        component: CopyrightVideos,
        page_id: PAGE_IDS.copyright_videos,
      },
      {
        path: ROUTE_NAMES.copyrightSearch,
        exact: true,
        component: CopyrightSearch,
        page_id: PAGE_IDS.copyright_search,
      },
      {
        path: ROUTE_NAMES.copyrightConfirm,
        exact: true,
        component: CopyrightConfirm,
        page_id: PAGE_IDS.copyright_confirm,
      },
      {
        path: ROUTE_NAMES.copyrightComplete,
        exact: true,
        component: CopyrightComplete,
        page_id: PAGE_IDS.copyright_complete,
      },
      {
        path: ROUTE_NAMES.game,
        exact: true,
        component: GameTop,
        page_id: PAGE_IDS.game,
      },
      {
        path: ROUTE_NAMES.apps,
        exact: true,
        component: Apps,
        page_id: PAGE_IDS.apps,
      },
      {
        path: ROUTE_NAMES.apps_category,
        exact: true,
        component: Apps,
        page_id: PAGE_IDS.apps_category,
      },
      {
        path: ROUTE_NAMES.appDetail,
        exact: true,
        component: AppDetail,
        page_id: PAGE_IDS.app_detail,
      },
      {
        path: ROUTE_NAMES.myapps,
        exact: true,
        component: MyAppsTop,
        page_id: PAGE_IDS.myapps,
      },
      {
        component: NotFound,
      },
    ],
  },
];
export default mobileRoutes;
