import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import MineServiceIds from '../../../../constants/serviceIds';
import { INFORMATION_KEY, SpMenuProps } from '../../../../constants/spMenu';
import { MORE_TEXT } from '../../../../constants/text';
import { ROUTE_NAMES } from '../../../../constants/routes';
import { UserProfileObject } from '../../../../services/userProfile/entity/UserProfile';
import Tap from '../../../atoms/Tap';
import Ellipsis from '../../../atoms/Ellipsis';
import Icon, { IconTypes } from '../../../atoms/Icon';
import LazyImage, { LazyImageType } from '../../../atoms/LazyImage';
import { MENU_LIST_TOP, MENU_LIST_BOTTOM } from './constants/menuListItems';

export interface Props {
  sectionId: string;
  pathname: string;
  isAmebaUser: boolean;
  user: UserProfileObject;
  notifications: number;
}

interface State {
  showAllMenu: boolean;
}

// 初期表示するメニューの数
const DEFAULT_MENU_NUM = 14;

/**
 * タブレット用メニューリスト
 * @param {sectionId} string
 * @param {pathname} string
 * @param {isAmebaUser} boolean
 * @param {user} Uer
 * @returns {any}
 * @constructor
 */
export default class MenuList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showAllMenu: MENU_LIST_TOP.length <= DEFAULT_MENU_NUM,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      showAllMenu: true,
    });
  }

  /**
   * メニューアイテム
   */
  renderMenuItem(menu: SpMenuProps, showIcon: boolean) {
    const { sectionId, pathname, notifications, isAmebaUser } = this.props;
    // AmebaIdを保持していないユーザーはブログ管理の遷移をnonamebaidに変更
    const isNonamebaidFlag = !isAmebaUser && menu.key === 'menu-blog';
    const isExternal = isNonamebaidFlag ? false : menu.isExternal;
    const linkUrl = isNonamebaidFlag ? ROUTE_NAMES.nonamebaid : menu.linkUrl;
    // 内部向けリンクか外部向けリンクかを振り分け
    let href = '';
    let to = '';
    if (isExternal || menu.isAmebame) {
      href = linkUrl;
    } else {
      to = linkUrl;
    }

    return (
      <li
        className={cx('MenuList_Item', {
          '-selected': pathname === linkUrl,
        })}
        key={menu.key}
      >
        <Tap
          className="MenuList_Item_Link"
          href={href}
          to={to}
          track={{
            section_id: sectionId,
            tap_id: menu.key,
          }}
          useFid={menu.isAmebame || isExternal}
        >
          {showIcon && (
            <Icon className="MenuList_Item_Link_Icon" iconClass={menu.icon} />
          )}
          <p className="MenuList_Item_Link_Title">{menu.title}</p>
          {menu.key === INFORMATION_KEY && notifications > 0 && (
            <span className="MenuList_Item_Link_Notification">
              {notifications > 99 ? '99+' : notifications}
            </span>
          )}
          {showIcon && isExternal && (
            <Icon
              className="MenuList_Item_Link_SnsIcon"
              iconClass={IconTypes.SnsConnect}
            />
          )}
        </Tap>
      </li>
    );
  }

  render() {
    const { sectionId, user } = this.props;
    const { showAllMenu } = this.state;
    // デフォルトでは特定数のみ表示
    const menuList = showAllMenu
      ? MENU_LIST_TOP
      : MENU_LIST_TOP.slice(0, DEFAULT_MENU_NUM);

    const profileImage = get(user, 'profileImage.originUrl')
      ? `${user.profileImage.originUrl}?cat=80`
      : null;

    return (
      <nav className="MenuList">
        {/* プロフィール情報 */}
        <Tap
          className="MenuList_Profile"
          href={`${process.env.DOMAIN_PROFILE_AMEBA_URL}/me`}
          track={{
            section_id: sectionId,
            tap_id: 'profile',
            service_id: MineServiceIds.Ameba,
          }}
          target="_blank"
          useFid
        >
          <LazyImage
            imageType={LazyImageType.Icon_User}
            src={profileImage}
            alt={`${user.name}の画像`}
            height={40}
            width={40}
            className="MenuList_Profile_Image"
          />
          <Ellipsis className="MenuList_Profile_Name">{user.name}</Ellipsis>
        </Tap>

        {/* 上段メニュー */}
        <ul className="MenuList_Top clearfix">
          {menuList.map((menu) => {
            return this.renderMenuItem(menu, true);
          })}
        </ul>
        {
          // メニューが一部しか表示されていない場合、もっと見るボタンを表示
          !showAllMenu && (
            <Tap
              className="MenuList_More"
              onClick={() => {
                this.handleClick();
              }}
            >
              {MORE_TEXT}
              <Icon
                className="MenuList_More_Icon"
                iconClass={IconTypes.TriangleDown}
              />
            </Tap>
          )
        }

        {/* 下段メニュー */}
        <ul className="MenuList_Bottom clearfix">
          {MENU_LIST_BOTTOM.map((menu) => {
            return this.renderMenuItem(menu, false);
          })}
        </ul>
      </nav>
    );
  }
}
