import { SP_MENU, SpMenuProps } from '../../../../../constants/spMenu';

/**
 * 上段メニューリスト
 */
export const MENU_LIST_TOP: SpMenuProps[] = [
  SP_MENU.home,
  SP_MENU.blog,
  SP_MENU.myapps,
  SP_MENU.official,
  SP_MENU.dokusho,
  SP_MENU.game,
  SP_MENU.amebanews,
];

/**
 * 中段メニューリスト
 */
export const MENU_LIST_MIDDLE: SpMenuProps[] = [
  SP_MENU.message,
  SP_MENU.setting,
];

/**
 * 下段メニューリスト
 */
export const MENU_LIST_BOTTOM: SpMenuProps[] = [SP_MENU.contact, SP_MENU.rules];
