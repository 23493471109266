import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { UserProfileObject } from '../../../../services/userProfile/entity/UserProfile';

import MenuList from './MenuList';
import LoginMenuList from './LoginMenuList';

const SECTION_ID = 'sp-side-menu';

const mapStateToProps = (state) => {
  return {
    pathname: get(state, 'router.location.pathname') || '',
    isAuthenticated: get(state, 'loginInfo.isAuthenticated'),
    isAmebaUser: !!get(state, 'loginInfo.user.amebaId'),
    user: get(state, 'loginInfo.user'),
    unreadCount: get(state, 'notification.unreadCount'),
  };
};

interface Props {
  pathname: string;
  isAuthenticated: boolean;
  isAmebaUser: boolean;
  user: UserProfileObject;
  unreadCount: number;
}

/**
 * SPサイドメニュー
 * 画面幅が600px以上の時だけ表示
 */
export const SpSideMenu: React.FC<Props> = ({
  pathname,
  isAuthenticated,
  isAmebaUser,
  user,
  unreadCount,
}) => {
  // 会員・非会員でメニューが変更する
  return isAuthenticated ? (
    <MenuList
      sectionId={SECTION_ID}
      pathname={pathname}
      isAmebaUser={isAmebaUser}
      user={user}
      notifications={unreadCount}
    />
  ) : (
    <LoginMenuList sectionId={SECTION_ID} />
  );
};

export default connect(mapStateToProps)(SpSideMenu);
