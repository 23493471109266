import { AsyncStatus } from '../../../../lib/ReduxUtils';
import MangafeedNewlyEntity from '../../../../services/dokusho/entity/MangafeedNewly';
import {
  MangaCouponSchema,
  MangafeedRecommendSchema,
  MangafeedSerialSchema,
} from '../../../../services/dokusho/response/MangaService';
import { create } from '../../FetchrConfigFactory';

/**
 * マンガフィードモジュール
 */
export const mangafeedConfig = create('home/mangafeed');
export const mangafeedSerialConfig = create('home/mangafeed_serial');

export interface MangafeedReadPayload {
  coupon: MangaCouponSchema;
  newly: MangafeedNewlyEntity[];
  recommend: MangafeedRecommendSchema;
  serial: MangafeedSerialReadPayload;
  isMangafeedLoading: boolean;
}
export interface MangafeedSerialReadPayload extends MangafeedSerialSchema {
  status: AsyncStatus;
  offset?: number;
}
