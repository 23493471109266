import { getAsyncComponent } from '../../../lib/ComponentUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';
import { fetchSettings } from '../../../contexts/Settings/SettingsMangafeed';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('あなたのマンガ新着設定', 'other'));
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchSettings());
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./SettingsMangafeed'))),
);
