import { AdcrossAdSchema } from '../../../../services/adcross/response/AdcrossService';
import { MypageApiMaintenanceText } from '../../../../types/apis/mypage-api';
import { create } from '../../FetchrConfigFactory';

export const maintenanceConfig = create('home/maintenance');

export interface MaintenanceReadPayload {
  maintenanceText: MypageApiMaintenanceText[];
  newInformation: AdcrossAdSchema[];
}
