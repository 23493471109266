import FetchrSharedFactory from '../../FetchrSharedFactory';
import { maintenanceConfig, MaintenanceReadPayload } from './MaintenanceConfig';

/**
 * SPホームTOP
 */
export function readMaintenance(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MaintenanceReadPayload>(maintenanceConfig.name);
}
