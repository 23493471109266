import React from 'react';

import { getAccountsRegistrationLinkUrl } from '../../../../lib/UrlUtils';
import { REGISTER_LOGIN, LOGIN } from '../../../../constants/amebaLogin';
import Button from '../../../atoms/Button';
import Icon, { IconTypes } from '../../../atoms/Icon';
import Tap from '../../../atoms/Tap';

interface Props {
  sectionId: string;
}

/**
 * 非会員用ログインメニュー
 * @param {sectionId} string
 * @returns {any}
 * @constructor
 */

const LoginMenuList: React.FC<Props> = ({ sectionId }) => (
  <nav className="LoginMenuList">
    <div className="LoginMenuList_WelcomeMessage">ようこそ、ゲストさん</div>
    <div className="LoginMenuList_LoginButton_Container">
      <Button
        href={LOGIN.linkUrl}
        buttonType={LOGIN.buttonType}
        track={{
          section_id: sectionId,
          tap_id: LOGIN.key,
        }}
        useFid
        className="LoginMenuList_LoginButton"
      >
        {`アメーバで${LOGIN.text}`}
      </Button>
    </div>
    <Tap
      className="LoginMenuList_SnsList_Item_Mail"
      href={getAccountsRegistrationLinkUrl(REGISTER_LOGIN.linkUrl, {
        utm_medium: 'ameba',
        utm_source: 'www.ameba.jp',
        utm_campaign: 'ameba_home-sidebar_register_link',
      })}
      track={{
        section_id: sectionId,
        tap_id: REGISTER_LOGIN.key,
      }}
    >
      <Icon
        iconClass={IconTypes.Mail}
        className="LoginMenuList_SnsList_Item_Icon"
      />
      <p className="LoginMenuList_SnsList_Item_Title">{`メールアドレスから${REGISTER_LOGIN.text}`}</p>
    </Tap>
  </nav>
);

export default LoginMenuList;
