import { Request } from 'express';
import { produce } from 'immer';
import { readBlogHistory } from '../../../fetchr/services/Home/BlogHistory/BlogHistoryShared';
import { handleActions, ThunkActionCreator } from '../../../lib/ReduxUtils';
import { BlogHistory } from '../../../types/type/blog-history';

interface State {
  history: BlogHistory[];
  isLoading: boolean;
}

interface Payload {
  history?: BlogHistory[];
}

const initialState: State = {
  history: null,
  isLoading: true,
};

/**
 * 最近見たブログ
 */
export const FETCH_BLOG_HISTORY = 'Home/HomeTop/FETCH_BLOG_HISTORY';
export const FETCH_BLOG_HISTORY_SUCCESS =
  'Home/HomeTop/FETCH_BLOG_HISTORY_SUCCESS';
export const FETCH_BLOG_HISTORY_FAILURE =
  'Home/HomeTop/FETCH_BLOG_HISTORY_FAILURE';

export const fetchHistory: ThunkActionCreator = (req?: Request) => {
  return async (dispatch, getState) => {
    try {
      // amebaId所持者のみ適用
      const loginInfo = getState().loginInfo;
      if (!loginInfo.user || !loginInfo.user.amebaId) {
        dispatch({
          type: FETCH_BLOG_HISTORY_SUCCESS,
          payload: { history: [] },
        });
        return;
      }

      const { data } = await readBlogHistory(req);
      dispatch({ type: FETCH_BLOG_HISTORY_SUCCESS, payload: data });
    } catch {
      dispatch({ type: FETCH_BLOG_HISTORY_FAILURE });
    }
  };
};

export const homeHistory = handleActions<State, Payload>(
  {
    [FETCH_BLOG_HISTORY](state) {
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    },
    [FETCH_BLOG_HISTORY_SUCCESS](state, { payload }) {
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.history = payload.history;
      });
    },
    [FETCH_BLOG_HISTORY_FAILURE](state) {
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    },
  },
  initialState,
);
