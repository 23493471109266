import { Request } from 'express';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { HomeGameReadPayload } from '../../../fetchr/services/Home/App/AppConfig';
import { readAppData } from '../../../fetchr/services/Home/App/AppShared';
import { HomeBlogRankingReadPayload } from '../../../fetchr/services/Home/BlogRanking/BlogRankingConfig';
import { readBlogRankingData } from '../../../fetchr/services/Home/BlogRanking/BlogRankingShared';
import { readSpHomeFooterCarouselBannerData } from '../../../fetchr/services/Home/FooterCarouselBanner/FooterCarouselBannerShared';
import { readSpHomeData } from '../../../fetchr/services/Home/HomeTop/HomeTopShared';
import { readSpHomeInitialData } from '../../../fetchr/services/Home/HomeTopInitial/HomeTopInitialShared';
import { getLatestEntry } from '../../../fetchr/services/Home/LatestEntry/LatestEntryShared';
import { readMaintenance } from '../../../fetchr/services/Home/Maintenance/MaintenanceShared';
import {
  readNotificationCenterData,
  updateNotificaitonCenterPreregist,
} from '../../../fetchr/services/Home/NotificationCenter/NotificationCenterShared';
import { readPopularTalentBlog } from '../../../fetchr/services/Home/PopularTalentBlog/PopularTalentBlogShared';
import { readRecommendNewGameData } from '../../../fetchr/services/Home/RecommendNewGame/RecommendNewGameShared';
import { readNotificationUpdate } from '../../../fetchr/services/Notifications/Notifications/NotificationsShared';
import {
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { HomeNotificationCenterItemEntity } from '../../../services/_sp/entity/HomeNotificationCenterItemEntity';
import HomePopularTalentBlogEntity from '../../../services/_sp/entity/HomePopularTalentBlogEntity';
import { HomeRecommendNewGameEntity } from '../../../services/_sp/entity/HomeRecommendNewGameEntity';
import {
  HomeAmebaTopicsSchema,
  HomeDokushoSchema,
  HomeTopBannerSchema,
  HomeTutorialDataSchema,
} from '../../../services/_sp/response/HomeService';
import { AbemaProgramSchema } from '../../../services/abema/response/AbemaService';
import { AdcrossAdSchema } from '../../../services/adcross/response/AdcrossService';
import { AmebaConnectApiApplicationsPayload } from '../../../services/amebaConnect/response/AmebaConnectApiService';
import { MvbList } from '../../../services/blog/constant/mvbList';
import { BlogRankingModule } from '../../../services/blog/entity/BlogRankingModule';
import {
  BlogLatestEntrySchema,
  NotificationCenterDataSchema,
} from '../../../services/homeApi/response/HomeService';
import { SatoriServiceSchema } from '../../../services/satori/response/SatoriService';
import { RankingBlog } from '../../../services/stat100/response/BlogRankingService';
import { MypageApiMaintenanceText } from '../../../types/apis/mypage-api';
import { localStorage } from '../../../webApi/Storage';
import { fetchFollowFeed, fetchRecommendedBlogger } from '../FollowFeed';
import { fetchPopupData } from '../Popup';

interface State {
  dokusho: HomeDokushoSchema;
  game: HomeGameReadPayload;
  myApps: AmebaConnectApiApplicationsPayload[];
  blogLatestEntry: BlogLatestEntrySchema[];
  blogRankings: BlogRankingModule[];
  blogCategoryRanking: BlogRankingModule;
  blogRanking: HomeBlogRankingReadPayload;
  popularTalentBlog: HomePopularTalentBlogEntity[];
  satori: SatoriServiceSchema;
  maintenance: MypageApiMaintenanceText[];
  newInformation: AdcrossAdSchema[];
  abemaPickups: AbemaProgramSchema[];
  searchValue: string;
  notificationCenter: NotificationCenterDataSchema;
  recommendNewGame: HomeRecommendNewGameEntity;
  footerBanner: AdcrossAdSchema[];
  amebaTopics: HomeAmebaTopicsSchema;
  tutorial: HomeTutorialDataSchema;
  topBanner: HomeTopBannerSchema;
}

type Payload = Partial<State>;

const initialState: State = {
  dokusho: null,
  game: null,
  myApps: null,
  blogLatestEntry: null,
  blogRankings: null,
  blogCategoryRanking: null,
  blogRanking: null,
  popularTalentBlog: null,
  satori: null,
  maintenance: null,
  newInformation: null,
  abemaPickups: [],
  searchValue: '',
  notificationCenter: null,
  recommendNewGame: null,
  footerBanner: null,
  amebaTopics: null,
  tutorial: null,
  topBanner: null,
};

/**
 * ランキングとフォローフィード取得後に人気の芸能人ブログとおすすめブロガーを取得
 * @param {e.Request} req
 * @returns {(dispatch, getState) => Promise<void>}
 */
export const fetchBlogRankingAndFollowFeedAndRecommendBlogger: ThunkActionCreator =
  (req?: Request) => {
    return async (dispatch, getState) => {
      await Promise.all([
        // ランキング取得
        dispatch(fetchBlogRanking(req)),
        // FollowFeed取得
        dispatch(fetchFollowFeed(req)),
      ]);

      // getState()を使ってtotalRankとfollowFeedのデータを取得
      const { homeTop, homeFollowFeed } = getState();
      const totalRanking =
        get(homeTop, 'blogRanking.blogRanking.total.blogs') || [];
      const followFeed = get(homeFollowFeed, 'followFeed') || [];

      // follow feedが3件以下で、MVBを除いたブログリストが1件以上の場合は人気の芸能人ブログのデータを取得
      if (!!followFeed.length && followFeed.length <= 3) {
        // mvbを除いたRankingから3件取得
        const rankingWithoutMvb = totalRanking
          .filter((data) => {
            return !MvbList.some((mvb) => {
              return data.ameba_id === mvb.ameba_id;
            });
          })
          .slice(0, 3);

        // ランキングデータを使って人気の芸能人ブログを取得
        if (rankingWithoutMvb.length) {
          await dispatch(fetchPopularTalentBlog(req, rankingWithoutMvb));
        }
      }

      // follow feedのデータを使っておすすめブロガーを取得
      if (
        !!homeFollowFeed &&
        !!homeFollowFeed.followFeed &&
        !!homeFollowFeed.followFeed.length
      ) {
        // 取得したfollow feedのデータを元におすすめブロガーを取得
        await dispatch(fetchRecommendedBlogger(req, homeFollowFeed.followFeed));
      }
    };
  };

/**
 * ブログランキング
 */
export const FETCH_BLOG_RANKING = 'Home/HomeTop/FETCH_BLOG_RANKING';
export const fetchBlogRanking = createAction<Payload>(
  FETCH_BLOG_RANKING,
  async (req?: Request) => {
    const { data: blogRanking } = await readBlogRankingData(req);
    return { blogRanking };
  },
);

/**
 * 人気の芸能人ブログ
 */
export const FETCH_POPULAR_TALENT_BLOG =
  'Home/HomeTop/FETCH_POPULAR_TALENT_BLOG';
export const fetchPopularTalentBlog = createAction<Payload>(
  FETCH_POPULAR_TALENT_BLOG,
  async (req?: Request, blogList?: RankingBlog[]) => {
    const { data: popularTalentBlog } = await readPopularTalentBlog(
      req,
      blogList,
    );
    return { popularTalentBlog };
  },
);

/**
 * 人気のオフィシャルブログ
 */
export const FETCH_BLOG_LATEST_ENTRY = 'Home/HomeTop/FETCH_BLOG_LATEST_ENTRY';

export const fetchLatestEntry = createAction<Payload>(
  FETCH_BLOG_LATEST_ENTRY,
  async (req?: Request) => {
    const { data } = await getLatestEntry(req);
    return data;
  },
);

/**
 * Amebaトピックス
 * ABEMA
 * Amebaマンガ
 * 検索ボックステキスト
 */
export const FETCH_SP_HOME = 'Home/HomeTop/FETCH_SP_HOME';
export const fetchSpHomeData = createAction<Payload>(
  FETCH_SP_HOME,
  async (req: Request) => {
    const { data } = await readSpHomeData(req);
    return data;
  },
);

/**
 * マイアプリ
 * ゲーム事前エントリー
 * ゲーム関連
 */
export const FETCH_APP_DATA = 'Home/HomeTop/FETCH_APP_DATA';

export const fetchAppData = createAction(
  FETCH_APP_DATA,
  async (req?: Request) => {
    const { data } = await readAppData(req);
    const applications = data.applications || [];
    const connectApplications = data.connectApplications || [];

    // 認可アプリ（connectApplications）からクローズ済アプリを除外するためにアプリ一覧（applications）と比較
    const resultMyApps = connectApplications.filter((app) => {
      return applications.find(
        (connectApp) => connectApp.client_id === app.client_id,
      );
    });

    // カテゴリが 'game' のアプリのみを絞り込み
    const gameApps = applications.filter((app) => {
      return app.category === 'game';
    });

    // 表示対象をdisplay_orderの降順に並べる
    const resultGameApps = orderBy(gameApps, 'display_order', 'desc');

    return {
      myApps: resultMyApps,
      game: {
        gameRecommend: resultGameApps,
      },
    };
  },
);

/**
 * メンテナンステキスト
 */
export const FETCH_HOME_MAINTENANCE = 'Home/HomeTop/FETCH_HOME_MAINTENANCE';
export const fetchHomeMaintenance = createAction(
  FETCH_HOME_MAINTENANCE,
  async (req?: Request) => {
    const { data } = await readMaintenance(req);
    return data;
  },
);

/**
 * おすすめゲーム
 */
export const FETCH_RECOMMEND_NEW_GAME_DATA =
  'Home/HomeTop/FETCH_RECOMMEND_NEW_GAME_DATA';
export const fetchRecommendNewGame = createAction(
  FETCH_RECOMMEND_NEW_GAME_DATA,
  async (req?: Request) => {
    const { data } = await readRecommendNewGameData(req);
    return data;
  },
);

/**
 * Notification
 */
export const SET_NOTIFICATION_CENTER_DATA =
  'Home/HomeTop/SET_NOTIFICATION_CENTER_DATA';
export const setNotificationCenterData = createAction<Payload>(
  SET_NOTIFICATION_CENTER_DATA,
  (notificationCenter: NotificationCenterDataSchema) => {
    return {
      notificationCenter,
    };
  },
);

const NOTIFICATION_CENTER_STORAGE_PREFIX = 'notification-center-';

/**
 * 通知センターの通知を取得するアクション
 * @returns {(dispatch, getState) => Promise<any>}
 */
export const fetchNotificationCenterData: ThunkActionCreator = (req?) => {
  return async (dispatch) => {
    // APIコール
    try {
      const { data: notificationCenter } =
        await readNotificationCenterData(req);

      // localStorageに保存された既読フラグを見たい
      notificationCenter.notifications.forEach((notification) => {
        notification.unread = !!localStorage.getItem(
          NOTIFICATION_CENTER_STORAGE_PREFIX + notification.type,
        );
      });
      dispatch(setNotificationCenterData(notificationCenter));
    } catch {
      dispatch(setNotificationCenterData(null));
    }

    dispatch(fetchPopupData(null));
  };
};

export const TAP_NOTIFICATION_CENTER_ITEM =
  'Home/HomeTop/TAP_NOTIFICATION_CENTER_ITEM';
export const tapNotificationCenterItem = createAction(
  TAP_NOTIFICATION_CENTER_ITEM,
  async (notification: HomeNotificationCenterItemEntity) => {
    switch (notification.type) {
      case 'mailVerified':
      case 'birthday':
        localStorage.setItem(
          NOTIFICATION_CENTER_STORAGE_PREFIX + notification.type,
          'false',
        );
        break;
      case 'alert':
        await readNotificationUpdate(null, notification.notification_id);
        break;
      case 'preregister':
        await updateNotificaitonCenterPreregist(
          null,
          notification.extendedInfo.appId,
        );
        break;
      default:
        break;
    }
  },
);

/**
 * フッターカルーセルバナー
 */
export const FETCH_FOOTER_BANNER_DATA = 'Home/HomeTop/FETCH_FOOTER_BANNER_DATA';
export const fetchFooterCarouselBannerData = createAction(
  FETCH_FOOTER_BANNER_DATA,
  async (req?: Request) => {
    const { data: footerBanner } =
      await readSpHomeFooterCarouselBannerData(req);
    return footerBanner;
  },
);

/**
 * SSR時にuserInfoを使うdataを取得
 * - satori
 * - tutorial
 * - topBanner // こちらのみtutorialの結果によって出し分けなのでuserInfoつかわないが入っている
 */
export const FETCH_HOME_INITIAL_DATA = 'Home/HomeTop/FETCH_HOME_INITIAL_DATA';
export const fetchHomeInitialData = createAction(
  FETCH_HOME_INITIAL_DATA,
  async (req?: Request) => {
    const { data } = await readSpHomeInitialData(req);
    return data;
  },
);

export const homeTop = handleActions<State, Payload>(
  {
    [FETCH_APP_DATA](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_BLOG_RANKING](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_POPULAR_TALENT_BLOG](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_BLOG_LATEST_ENTRY](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_SP_HOME](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_HOME_MAINTENANCE](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [SET_NOTIFICATION_CENTER_DATA](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_RECOMMEND_NEW_GAME_DATA](state, { payload, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    },
    [FETCH_FOOTER_BANNER_DATA](state, { payload, error }) {
      if (error) {
        return state;
      } else {
        return {
          ...state,
          ...payload,
        };
      }
    },
    [FETCH_HOME_INITIAL_DATA](state, { payload, error }) {
      if (error) {
        return state;
      } else {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
  initialState,
);
