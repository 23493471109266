import { SP_MENU, SpMenuProps } from '../../../../../constants/spMenu';

/**
 * 上段メニューリスト
 */
export const MENU_LIST_TOP: SpMenuProps[] = [
  SP_MENU.home,
  SP_MENU.information,
  SP_MENU.blog,
  SP_MENU.myapps,
  SP_MENU.official,
  SP_MENU.game,
  SP_MENU.amebanews,
  SP_MENU.dokusho,
  SP_MENU.satori,
  SP_MENU.pigg,
  SP_MENU.dotmoney,
  SP_MENU.coin,
  SP_MENU.message,
];

/**
 * 下段メニューリスト
 */
export const MENU_LIST_BOTTOM: SpMenuProps[] = [
  SP_MENU.setting,
  SP_MENU.rules,
  SP_MENU.specifiedCommercialTransactions,
  SP_MENU.contact,
];
