import { setTitle } from '../../../contexts/AppShell/SpHeader';
import { fetchInitialData, fetchLazyData } from '../../../contexts/Top/Top';
import { getAsyncComponent } from '../../../lib/ComponentUtils';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';

export default composeRoute({
  async routerLoader({ req, dispatch }) {
    dispatch(setTitle(null, 'top'));
    await dispatch(fetchInitialData(req));
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchLazyData());
  },
})(
  composeRouterPermission(
    RouterPermission.UNAUTHENTICATED,
    '/home',
  )(getAsyncComponent(() => import('./Top'))),
);
