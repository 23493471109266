import { create } from '../../FetchrConfigFactory';
import HomeSatoriEntity from '../../../../services/satori/entity/SatoriEntity';
import {
  HomeTopBannerSchema,
  HomeTutorialDataSchema,
} from '../../../../services/_sp/response/HomeService';

export const homeTopInitialConfig = create('home/home_top_initial');

export interface SpHomeInitialDataReadPayload {
  satori: HomeSatoriEntity;
  tutorial: HomeTutorialDataSchema;
  topBanner: HomeTopBannerSchema;
}
