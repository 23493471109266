import { getAsyncComponent } from '../../../lib/ComponentUtils';
import { fetchNotifications } from '../../../contexts/Notifications';
import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('お知らせ', 'other'));
  },
  async clientLoader({ dispatch }) {
    await dispatch(fetchNotifications());
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./Notifications'))),
);
