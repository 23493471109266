import { Request } from 'express';
import { createAction, handleActions } from '../../../lib/ReduxUtils';
import AmebaMoneyInfo from '../../../services/_common/entity/AmebaMoneyInfo';
import Purchase from '../../../services/snsApi/entity/Purchase';
import { getModalMenu } from '../../../fetchr/services/AppShell/ModalMenu/ModalMenuShared';

const initialState = {
  purchase: null,
  dotMoney: null,
};

interface StateAndPayload {
  purchase: Purchase;
  dotMoney: AmebaMoneyInfo;
}

export const FETCH_MODALMENU = 'AppShell/Purchase/FETCH_MODALMENU';

/**
 * モーダルメニューで必要なデータを引き出し
 */
export const fetchModalMenu = createAction<StateAndPayload>(
  FETCH_MODALMENU,
  async (req?: Request) => {
    const { data } = await getModalMenu(req);
    return {
      purchase: data.purchase,
      dotMoney: data.dotMoney,
    };
  },
);

export const modalMenu = handleActions<StateAndPayload, StateAndPayload>(
  {
    [FETCH_MODALMENU](state, { payload }) {
      return payload;
    },
  },
  initialState,
);
