import React from 'react';
import get from 'lodash/get';
import AmebaMoneyInfo from '../../../../services/_common/entity/AmebaMoneyInfo';
import Purchase from '../../../../services/snsApi/entity/Purchase';
import MineServiceIds from '../../../../constants/serviceIds';
import { ROUTE_NAMES } from '../../../../constants/routes';
import { SpMenuProps } from '../../../../constants/spMenu';
import { UserProfileObject } from '../../../../services/userProfile/entity/UserProfile';
import Tap, { TapProps } from '../../../atoms/Tap';
import Loading from '../../../atoms/Loading';
import LazyImage, { LazyImageType } from '../../../atoms/LazyImage';
import Icon, { IconTypes } from '../../../atoms/Icon';
import HorizontalList, {
  HorizontalListItem,
} from '../../../atoms/HorizontalList';
import {
  MENU_LIST_TOP,
  MENU_LIST_MIDDLE,
  MENU_LIST_BOTTOM,
} from './constants/modalMenuItems';

interface Props {
  user: UserProfileObject;
  onClose: () => void; // 閉じる時のアクション
  sectionId: string;
  dotMoney?: AmebaMoneyInfo;
  purchase?: Purchase;
}

/**
 * コイン・ドマネ情報の表示に必要なインターフェース
 */
interface MoneyProps {
  isLoading: boolean;
  key: string;
  text: string;
  money: number;
  icon: string;
  showExclam: boolean;
  tapProps: TapProps;
  error?: Error;
}

/**
 * モーダルメニュー
 */
export class ModalMenu extends React.Component<Props> {
  /**
   * コイン・マネー情報
   */
  renderMoneyInfo(props: MoneyProps) {
    const { key, isLoading, error } = props;

    return (
      <HorizontalListItem className="ModalMenu_MoneyInfo_List" key={key}>
        <Tap
          className="ModalMenu_MoneyInfo_List_Tap"
          useFid
          track={{
            section_id: this.props.sectionId,
            tap_id: `modal-menu-${key}`,
          }}
          {...props.tapProps}
        >
          <Icon
            className={`ModalMenu_MoneyInfo_List_Tap_Icon -${key}`}
            iconClass={props.icon}
          />
          <Loading
            className="ModalMenu_MoneyInfo_List_Tap_Loading"
            isActive={isLoading}
          />
          {
            /* データ取得エラー */
            !isLoading && error && (
              <p className="ModalMenu_MoneyInfo_List_Tap_ErrorText">
                データが取得できません
              </p>
            )
          }
          {
            /* データ取得&表示 */
            !isLoading && !error && (
              <div className="ModalMenu_MoneyInfo_List_Tap_Info">
                <p className="ModalMenu_MoneyInfo_List_Tap_Info_Text">
                  {props.text}
                </p>
                <p className="ModalMenu_MoneyInfo_List_Tap_Info">
                  {props.money}
                </p>
                {
                  /* 1日以内に期限が切れそうなコインがあったら注意喚起 */
                  props.showExclam && (
                    <Icon
                      className="ModalMenu_MoneyInfo_List_Tap_Expire"
                      iconClass={IconTypes.Exclam}
                    />
                  )
                }
              </div>
            )
          }
        </Tap>
      </HorizontalListItem>
    );
  }

  /**
   * メニュー
   * @param {SpMenuProps[]} list - メニューのアイテムデータ
   * @param {boolean} withIcon - アイコン付けるか否か
   * @param {string} className - ポジション名
   */
  renderMenu(list: SpMenuProps[], withIcon: boolean, className: string) {
    const LAYOUT_NUM = 3; // レイアウト: 横並びの数
    const layout = list.length > LAYOUT_NUM ? LAYOUT_NUM : list.length;
    const { user } = this.props;

    return (
      <ul
        className={`ModalMenuList -${className}`}
        key={`modalMenu-${className}`}
      >
        {list.map((item) => {
          // AmebaIdを保持していないユーザーはブログ管理の遷移をnonamebaidに変更
          const isAmebaUser = !!get(user, 'amebaId');
          const isNonamebaidFlag = !isAmebaUser && item.key === 'menu-blog';
          const isExternal = isNonamebaidFlag ? false : item.isExternal;
          const linkUrl = isNonamebaidFlag
            ? ROUTE_NAMES.nonamebaid
            : item.linkUrl;
          // 内部向けリンクか外部向けリンクかを振り分け
          let href = '';
          let to = '';
          if (item.isAmebame || isExternal) {
            href = linkUrl;
          } else {
            to = linkUrl;
          }
          return (
            <li
              className="ModalMenuList_Item_List"
              key={item.key}
              style={{ width: `${100 / layout}%` }}
            >
              <Tap
                className="ModalMenuList_Item_Link"
                href={href}
                to={to}
                track={{
                  section_id: this.props.sectionId,
                  tap_id: item.key,
                }}
                useFid={item.isAmebame || isExternal}
                onClick={() => {
                  // サイト内の場合メニューを閉じる
                  !isExternal && this.props.onClose();
                }}
              >
                {withIcon && (
                  <Icon
                    className="ModalMenuList_Item_Link_Icon"
                    iconClass={item.icon}
                  />
                )}
                <p className="ModalMenuList_Item_Link_Title">{item.title}</p>
              </Tap>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { user, onClose, sectionId, dotMoney, purchase } = this.props;
    const profileImage = get(user, 'profileImage.originUrl')
      ? `${get(user, 'profileImage.originUrl')}?cat=80`
      : null;

    return (
      <div className="ModalMenu">
        <div className="ModalMenu_Container">
          {/* プロフィール情報 */}
          <div className="ModalMenu_UserInfo">
            <Tap
              className="ModalMenu_UserInfo_Link"
              href={`${process.env.DOMAIN_PROFILE_AMEBA_URL}/me`}
              track={{
                section_id: sectionId,
                tap_id: 'profile',
                service_id: MineServiceIds.Ameba,
              }}
              useFid
            >
              <LazyImage
                imageType={LazyImageType.Icon_User}
                src={profileImage}
                alt={`${user.name}の画像`}
                height={40}
                width={40}
                className="ModalMenuList_Profile_Image"
              />
              <div className="ModalMenuList_Profile">
                <p className="ModalMenuList_Profile_Name">{user.name}</p>
              </div>
            </Tap>
          </div>
          {/* コイン・マネー情報 */}
          <HorizontalList className="ModalMenu_MoneyInfo">
            {
              /* コイン情報 */
              this.renderMoneyInfo({
                isLoading: !purchase,
                key: 'coin',
                text: 'コイン',
                money: get(purchase, 'result.balance'),
                icon: IconTypes.Coin,
                showExclam: !!get(purchase, 'result.expireDate'),
                tapProps: {
                  to: ROUTE_NAMES.coinSummary,
                  onClick: onClose,
                },
                error: purchase && purchase.error,
              })
            }
            {
              /* ドマネ情報 */
              this.renderMoneyInfo({
                isLoading: !dotMoney,
                key: 'dotmoney',
                text: 'マネー',
                money: dotMoney?.result?.amount,
                icon: IconTypes.Dotmoney,
                showExclam: !!dotMoney?.result?.expireAmount,
                tapProps: {
                  href: 'https://d-money.jp/mall/?_g=0',
                },
                error: dotMoney?.error,
              })
            }
          </HorizontalList>
          {this.renderMenu(MENU_LIST_TOP, true, 'top')}
          {this.renderMenu(MENU_LIST_MIDDLE, false, 'middle')}
          {this.renderMenu(MENU_LIST_BOTTOM, false, 'bottom')}
        </div>
        <Tap className="ModalMenu_CloseButton" onClick={onClose}>
          <Icon iconClass={IconTypes.Close} />
        </Tap>
      </div>
    );
  }
}
