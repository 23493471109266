import {
  composeRoute,
  composeRouterPermission,
  RouterPermission,
} from '../../../lib/ReactRouterUtils';
import { getAsyncComponent } from '../../../lib/ComponentUtils';
import { setTitle } from '../../../contexts/AppShell/SpHeader';
import { fetchTradelaw } from '../../../contexts/Tradelaw';

export default composeRoute({
  async routerLoader({ dispatch }) {
    dispatch(setTitle('特定商取引法に基づく表記', 'other'));
  },
  async clientLoader({ dispatch }, { params }) {
    await dispatch(fetchTradelaw(params));
  },
})(
  composeRouterPermission(
    RouterPermission.AUTHENTICATED,
    '/',
    true,
  )(getAsyncComponent(() => import('./Tradelaw'))),
);
