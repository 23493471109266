import { AmebaConnectApiApplicationsPayload } from '../../../../services/amebaConnect/response/AmebaConnectApiService';
import { create } from '../../FetchrConfigFactory';
import { ApplicationServicePayload } from '../../../../services/appmng/response/AppmngApiService';

export interface HomeGameReadPayload {
  gameRecommend: ApplicationServicePayload[];
}

export const appConfig = create('home/app');
export interface HomeAppsReadPayload {
  connectApplications: AmebaConnectApiApplicationsPayload[];
  applications: ApplicationServicePayload[];
  game: HomeGameReadPayload;
}
