import { Request } from 'express';
import { HomePopupItemEntity } from '../../../../services/_sp/entity/SpHomePopupItemEntity';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import { popupConfig } from './PopupConfig';

export function readPopupData(req?: Request, notificationItemList?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomePopupItemEntity[]>(popupConfig.name, {
    comebackBonus: notificationItemList,
  });
}
