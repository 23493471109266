import { Request } from 'express';
import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  mangafeedConfig,
  MangafeedReadPayload,
  mangafeedSerialConfig,
  MangafeedSerialReadPayload,
} from './DokushoConfig';

/**
 * マンガフィード情報取得
 * @param {e.Request} req
 * @return {Promise<{data: MangafeedReadPayload}>}
 */
export function readMangafeedData(req: Request) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MangafeedReadPayload>(mangafeedConfig.name);
}

/**
 * マンガフィード情報取得
 * @param {e.Request} req
 * @param {number} offset
 * @return {Promise<{data: MangafeedSerialReadPayload}>}
 */
export function readMangafeedSerialData(req: Request, offset: number) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<MangafeedSerialReadPayload>(mangafeedSerialConfig.name, {
    offset,
  });
}
