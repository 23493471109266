import { modalMenu } from '../../contexts/AppShell/ModalMenu';
import { homeTop } from '../../contexts/Home/HomeTop';
import { homeHistory } from '../../contexts/Home/History';
import { homeFollowFeed } from '../../contexts/Home/FollowFeed';
import { homePopup } from '../../contexts/Home/Popup';
import { homeMangafeed } from '../../contexts/Home/MangaFeed';
import { notification } from '../../contexts/Notification/Notification';
import { spHeader } from '../../contexts/AppShell/SpHeader';
import { gameTop } from '../../contexts/Game/Top';
import { settingsConnect } from '../../contexts/Settings/Connect';
import { settingsMangafeed } from '../../contexts/Settings/SettingsMangafeed';
import { settingsNotifications } from '../../contexts/Settings/SettingsNotifications';
import { top } from '../../contexts/Top/Top';
import { coinSummary } from '../../contexts/Coin/Summary';
import configureStore from '../configureStore';
import { notifications } from '../../contexts/Notifications';
import { tradelaw } from '../../contexts/Tradelaw';
import { copyrightVideo } from '../../contexts/Copyright/Video';
import { copyrightSearch } from '../../contexts/Copyright/Search';
import { copyrightConfirm } from '../../contexts/Copyright/Confirm';
import { apps } from '../../contexts/Apps/Top';
import { appDetail } from '../../contexts/App/Detail';
import { myApps } from '../../contexts/MyApps/Top';

export default function createStore(history, initialState?) {
  return configureStore(history, initialState, {
    // ここにMobileで使うReducerを入れる
    top,
    gameTop,
    settingsConnect,
    settingsMangafeed,
    settingsNotifications,
    modalMenu,
    notification,
    homeTop,
    homeHistory,
    homeFollowFeed,
    homeMangafeed,
    homePopup,
    spHeader,
    coinSummary,
    notifications,
    tradelaw,
    copyrightVideo,
    copyrightSearch,
    copyrightConfirm,
    apps,
    appDetail,
    myApps,
  });
}
