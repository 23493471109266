import { NotificationCenterDataSchema } from '../../../../services/homeApi/response/HomeService';
import { create } from '../../FetchrConfigFactory';

export const notificationCenterConfig = create('home/notification_center');
export type HomeNotificationCenterRead = NotificationCenterDataSchema;

export const notificationCenterPreregistConfig = create(
  'home/notification_center/preregist',
);
export type HomeNotificationCenterPreregistUpdate = boolean;

// ポップアップ
export const notificationPopupUpdateConfig = create(
  'home/notification_popup/update',
);
export type HomeNotificationPopupUpdateSchema = boolean;
