import { Request } from 'express';
import get from 'lodash/get';
import { ThunkActionCreator, handleActions } from '../../../lib/ReduxUtils';

import { readNotifications } from '../../../fetchr/services/Notifications/Notifications/NotificationsShared';
const initialState = {
  unreadCount: 0,
};

interface StateAndPayload {
  unreadCount: number; // お知らせの未読数
}

export const FETCH_NOTIFICATION_COUNT =
  'contexts/Notification/FETCH_NOTIFICATION_COUNT';

/**
 * お知らせの未読数を取得
 */
export const fetchNotificationCount: ThunkActionCreator = (req?: Request) => {
  return async (dispatch) => {
    const limit = 0;
    const summaryOnly = true;
    const { data } = await readNotifications(req, limit, summaryOnly);
    const unreadCount = get(data, 'summary.unread');
    dispatch({ type: FETCH_NOTIFICATION_COUNT, payload: { unreadCount } });
  };
};

export const notification = handleActions<StateAndPayload, StateAndPayload>(
  {
    [FETCH_NOTIFICATION_COUNT](state, { payload }) {
      return payload;
    },
  },
  initialState,
);
