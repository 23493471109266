import { BlogRankingModule } from '../../../../services/blog/entity/BlogRankingModule';
import { create } from '../../FetchrConfigFactory';

export const blogRankingConfig = create('home/blogRanking');

export interface HomeBlogRankingReadPayload {
  blogRanking: {
    total: BlogRankingModule;
    hot: BlogRankingModule;
    newface: BlogRankingModule;
  };
  blogCategoryRanking: BlogRankingModule;
}
