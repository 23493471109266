import { Request } from 'express';
import { updateNotificationPopup } from '../../../fetchr/services/Home/NotificationCenter/NotificationCenterShared';
import { readPopupData } from '../../../fetchr/services/Home/Popup/PopupShared';
import {
  createAction,
  handleActions,
  ThunkActionCreator,
} from '../../../lib/ReduxUtils';
import { HomePopupItemEntity } from '../../../services/_sp/entity/SpHomePopupItemEntity';

interface State {
  popupItems: HomePopupItemEntity[];
  popupDisplayFlag: boolean;
}

type Payload = Partial<State>;

const initialState: State = {
  popupItems: [],
  popupDisplayFlag: false,
};

/**
 * Popup データ取得
 */
export const FETCH_POPUP_DATA = 'Home/HomePopup/FETCH_POPUP_DATA';
export const fetchPopupData = createAction(
  FETCH_POPUP_DATA,
  async (req?: Request) => {
    const { data: popupItems } = await readPopupData(req);
    return {
      popupItems,
      popupDisplayFlag: !!popupItems.length,
    };
  },
);

/**
 * Popup ポップアップ表示のフラグをtrue(=表示)に変更
 */
export const OPEN_POPUP = 'Home/HomePopup/OPEN_POPUP';
export const openPopup = createAction(OPEN_POPUP, async () => {
  return {
    popupDisplayFlag: true,
  };
});

/**
 * Popup ポップアップ表示のフラグをfalse(=非表示)に変更
 */
export const CLOSE_POPUP = 'Home/HomePopup/CLOSE_POPUP';
export const closePopup = createAction(CLOSE_POPUP, async () => {
  return {
    popupDisplayFlag: false,
  };
});

/**
 * Popup 次のポップアップに切り替え処理
 */
export const UPDATE_POPUP = 'Home/HomePopup/UPDATE_POPUP';
export const updatePopupData = createAction(
  UPDATE_POPUP,
  async (popupItems: HomePopupItemEntity[]) => {
    // copy popupItems
    const newPopupItems = popupItems.slice();
    // delete the first array data to display next popup
    newPopupItems.shift();
    return {
      popupItems: newPopupItems,
    };
  },
);

/**
 * Popup 閉じるボタンを押した際の更新処理
 */
const POPUP_INTERVAL = 300;
export const updatePopupContents: ThunkActionCreator = (
  req?: Request,
  popup?: HomePopupItemEntity,
) => {
  return async (dispatch, getState) => {
    dispatch(closePopup());

    await updateNotificationPopup(req, popup.messageCode);

    const { popupItems } = getState().homePopup;

    // popupの出たように見せるため300msの間隔を空けて実行
    setTimeout(() => {
      dispatch(updatePopupData(popupItems));
      dispatch(openPopup());
    }, POPUP_INTERVAL);
  };
};

export const homePopup = handleActions<State, Payload>(
  {
    [FETCH_POPUP_DATA](state, { payload, error }) {
      if (error) {
        return state;
      } else {
        return {
          ...state,
          ...payload,
        };
      }
    },
    [OPEN_POPUP](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [CLOSE_POPUP](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    [UPDATE_POPUP](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);
