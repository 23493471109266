import FetchrSharedFactory from '../../FetchrSharedFactory';
import {
  HomeNotificationCenterPreregistUpdate,
  HomeNotificationCenterRead,
  HomeNotificationPopupUpdateSchema,
  notificationCenterConfig,
  notificationCenterPreregistConfig,
  notificationPopupUpdateConfig,
} from './NotificationCenterConfig';

export function readNotificationCenterData(req?) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.read<HomeNotificationCenterRead>(notificationCenterConfig.name);
}

export function updateNotificaitonCenterPreregist(req, appId: string) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.update<HomeNotificationCenterPreregistUpdate>(
    notificationCenterPreregistConfig.name,
    { appId },
  );
}

// popupのステータスを更新
export function updateNotificationPopup(req, messageCode: string) {
  const fetchr = new FetchrSharedFactory({ req });
  return fetchr.update<HomeNotificationPopupUpdateSchema>(
    notificationPopupUpdateConfig.name,
    { messageCode },
  );
}
