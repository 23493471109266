import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';
import { flatten } from '../../../../lib/ArrayUtils';
import {
  AMEBA_RULES,
  AMEBA_SAFE,
  AMEBA_INQUIRY,
  AMEBA_HELP,
  AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS,
  ACCESS_DATA,
  AMEBA_PROFILE,
} from '../../../../constants/amebaLinks';
import { ROUTE_NAMES } from '../../../../constants/routes';
import { ProgramCopyrights } from '../../../../contexts/Common/AbemaCopyrights';
import TextLink from '../../../atoms/TextLink';
import HorizontalList, {
  HorizontalListItem,
} from '../../../atoms/HorizontalList';
import ViewSection from '../../../atoms/ViewSection';
import { SECTION_ID } from './TRACK';

const HELP_EXTERNAL_SERVICE_URL = `${process.env.DOMAIN_HELPS_URL}/rules/post_809.html`;
const HELP_COPYRIGHT_URL = `${process.env.DOMAIN_HELPS_URL}/rules/post_1303.html`;

const FOOTER_LINKS = [AMEBA_INQUIRY, AMEBA_HELP, AMEBA_RULES];

/**
 * ABEMA コピーライト
 */
const renderAbemaCopyrightView = (programs: ProgramCopyrights[]) => {
  if (!programs.length) {
    return null;
  }

  // SPはcopyrightだけの表示のため、ここでcopyrightだけの配列を作成
  const copyrightList = flatten(
    programs.map((program) => {
      return program.copyrights.map((copyright) => {
        return copyright;
      });
    }),
  ).filter((copyright, index, self) => {
    return self.indexOf(copyright) === index;
  });

  return (
    <ul className="SpFooter_AbemaCopyrights">
      {copyrightList.map((copyright, index) => {
        return <li key={`abema-copyright-${index}`}>{copyright}</li>;
      })}
    </ul>
  );
};

/**
 * 注意事項
 * Topページでのみ表示
 */
const renderNotesView = () => (
  <div className="SpFooter_Notes">
    <p className="SpFooter_Notes_Text">
      ※Ameba以外のサービスをご利用の場合
      <TextLink href={AMEBA_RULES.linkUrl} key={AMEBA_RULES.key}>
        {AMEBA_RULES.title}
      </TextLink>
      と
      <TextLink href={HELP_EXTERNAL_SERVICE_URL}>
        外部サービスとのID連携に関する同意事項
      </TextLink>
      に同意の上、ログインしてください。
    </p>
    <TextLink
      className="SpFooter_Notes_Text_BlockLink"
      href={HELP_COPYRIGHT_URL}
    >
      著作権等管理事業者許諾番号
    </TextLink>
  </div>
);

const mapStateToProps = (props) => {
  const pathname = get(props, 'router.location.pathname') || '';

  return {
    abemaCopyrights: props.abemaCopyrights.programs,
    existBottomOverlayAd:
      pathname === ROUTE_NAMES.top || pathname === ROUTE_NAMES.home,
    isTop: pathname === ROUTE_NAMES.top,
    showNotes: pathname === ROUTE_NAMES.top, // Topの場合のみtrue
  };
};

interface Props {
  abemaCopyrights?: ProgramCopyrights[];
  className?: string;
  existBottomOverlayAd?: boolean;
  isTop?: boolean;
  showNotes: boolean;
}

/**
 * SpFooter
 * 共通フッター
 */
export const SpFooter: React.FC<Props> = ({
  abemaCopyrights,
  className,
  existBottomOverlayAd = false,
  isTop = false,
  showNotes,
}) => (
  <ViewSection
    className={cx('SpFooter', className)}
    element="footer"
    track={{
      section_id: SECTION_ID,
    }}
  >
    {showNotes && renderNotesView()}
    <div className="SpFooter_Links">
      <TextLink
        className="SpFooter_Links_Safe"
        href={AMEBA_SAFE.linkUrl}
        key={AMEBA_SAFE.key}
        track={{
          section_id: SECTION_ID,
          tap_id: 'cs-trouble',
          content_ids: [AMEBA_SAFE.linkUrl],
        }}
      >
        {AMEBA_SAFE.title}
      </TextLink>
      <TextLink
        className="SpFooter_Links"
        href={ACCESS_DATA.linkUrl}
        key={ACCESS_DATA.key}
        track={{
          section_id: SECTION_ID,
          tap_id: ACCESS_DATA.key,
          content_ids: [ACCESS_DATA.linkUrl],
        }}
      >
        {ACCESS_DATA.title}
      </TextLink>
      {isTop && (
        <TextLink
          className="SpFooter_Links_Profile"
          href={AMEBA_PROFILE.linkUrl}
          key={AMEBA_PROFILE.key}
          track={{
            section_id: SECTION_ID,
            tap_id: AMEBA_PROFILE.key,
            content_ids: [AMEBA_PROFILE.linkUrl],
          }}
        >
          {AMEBA_PROFILE.title}
        </TextLink>
      )}
      <HorizontalList>
        {FOOTER_LINKS.map((link) => {
          return (
            <HorizontalListItem key={`footer-${link.key}`}>
              <TextLink
                href={link.linkUrl}
                key={link.key}
                track={{
                  section_id: SECTION_ID,
                  tap_id: link.key,
                  content_ids: [link.linkUrl],
                }}
              >
                {link.title}
              </TextLink>
            </HorizontalListItem>
          );
        })}
      </HorizontalList>
      <TextLink
        className="SpFooter_Links_Safe"
        href={AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS.linkUrl}
        key={AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS.key}
        track={{
          section_id: SECTION_ID,
          tap_id: AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS.key,
          content_ids: [AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS.linkUrl],
        }}
      >
        {AMEBA_SPECIFIED_COMMERCIAL_TRANSACTIONS.title}
      </TextLink>
    </div>
    {abemaCopyrights && renderAbemaCopyrightView(abemaCopyrights)}
    <div className="SpFooter_Copyright">
      <p>© CyberAgent, Inc.</p>
    </div>
    {/*オーバーレイ広告が存在するページの場合、コピーライトと重ならないように下に50px（広告の高さ）余白を取る*/}
    {existBottomOverlayAd ? <div style={{ height: '50px' }} /> : null}
  </ViewSection>
);

export default connect(mapStateToProps)(SpFooter);
